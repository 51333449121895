@import "colors";

nav{
width: 100%;
padding: 1rem;
display: flex;
justify-content:space-between;
align-items: center;
position: sticky;
z-index: 10;
top: 0%;
background-color: $first;

> h1{
    // background-color: yellow;
}

> main{
    display: flex;
    justify-content: flex-end;
    width: 70%;
// background-color: pink;

>a{
    margin: 1rem;
    color: $dark;

    &:hover{
        color: $third;
    }
}
}

}
